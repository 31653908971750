import classNames from 'classnames'
import {
  ChevronForwardOutline,
  CheckmarkCircleOutline,
  CloseCircleOutline,
} from 'react-ionicons'
import { IonIcon } from '@/src/icons'
import { Button } from '@/components/elements/Button'

export interface DialogProps {
  className?: string
  title: string
  description: string
  type: 'success' | 'error'
}

export const Dialog = ({
  className = '',
  title,
  description,
  type,
}: DialogProps) => {
  const isSuccess = type === 'success'
  const isError = type === 'error'

  return (
    <div
      className={classNames(
        'flex flex-col items-center mx-auto pt-10 px-4 py-12 max-w-md text-center bg-white rounded-lg sm:px-6 lg:px-8',
        className
      )}
    >
      <IonIcon
        icon={isSuccess ? CheckmarkCircleOutline : CloseCircleOutline}
        className={classNames(
          'flex-shrink-0 mb-6 w-12 fill-current',
          isSuccess && 'text-green-400',
          isError && 'text-alert'
        )}
        aria-hidden
      />
      <h1 className="text-2xl">{title}</h1>
      <p className="mb-12 py-4 font-body text-base font-semibold leading-6">
        {description}
      </p>
      <div className="w-full">
        <Button
          href="/"
          className="w-full"
          variant="default"
          label="Torna a la pàgina principal"
          icon={<IonIcon icon={ChevronForwardOutline} />}
        />
      </div>
    </div>
  )
}
